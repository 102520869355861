/* tslint:disable */
/* eslint-disable */
/**
 * Sunrise char API
 * chat API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnswerUserInteractionDto
 */
export interface AnswerUserInteractionDto {
    /**
     * 
     * @type {string}
     * @memberof AnswerUserInteractionDto
     */
    'userInteractionId': string;
    /**
     * 
     * @type {string}
     * @memberof AnswerUserInteractionDto
     */
    'messageResponseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerUserInteractionDto
     */
    'textValueResponse'?: string;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'attachmentName': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'attachmentSize': number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'bucketName': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'thumbnailKey'?: string;
    /**
     * 
     * @type {Message}
     * @memberof Attachment
     */
    'message': Message;
}
/**
 * 
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'attachmentName': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentDto
     */
    'attachmentSize': number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'attachmentUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'thumbnailUrl'?: string;
}
/**
 * 
 * @export
 * @interface AttachmentReceiveDto
 */
export interface AttachmentReceiveDto {
    /**
     * 
     * @type {string}
     * @memberof AttachmentReceiveDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentReceiveDto
     */
    'attachmentName': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentReceiveDto
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentReceiveDto
     */
    'attachmentSize': number;
}
/**
 * 
 * @export
 * @interface BotEntrypoint
 */
export interface BotEntrypoint {
    /**
     * 
     * @type {string}
     * @memberof BotEntrypoint
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypoint
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypoint
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypoint
     */
    'interactionSession'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypoint
     */
    'targetUserInteraction'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypoint
     */
    'status': BotEntrypointStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BotEntrypointStatusEnum {
    Running = 'running',
    Paused = 'paused',
    Canceled = 'canceled',
    NotStarted = 'not_started',
    Completed = 'completed'
}

/**
 * 
 * @export
 * @interface BotEntrypointDto
 */
export interface BotEntrypointDto {
    /**
     * 
     * @type {string}
     * @memberof BotEntrypointDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypointDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypointDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypointDto
     */
    'messageGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypointDto
     */
    'interactionSession'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypointDto
     */
    'targetUserInteraction'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotEntrypointDto
     */
    'status': BotEntrypointDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BotEntrypointDtoStatusEnum {
    Running = 'running',
    Paused = 'paused',
    Canceled = 'canceled',
    NotStarted = 'not_started',
    Completed = 'completed'
}

/**
 * 
 * @export
 * @interface BotMessage
 */
export interface BotMessage {
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'createdAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof BotMessage
     */
    'isStartMessage': boolean;
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof BotMessage
     */
    'delay': number;
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'actionAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'actionParameters'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'type': BotMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'responseType': BotMessageResponseTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BotMessage
     */
    'nextNode': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'responseVariable'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotMessage
     */
    'responseValue'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BotMessageTypeEnum {
    Message = 'message',
    Response = 'response'
}
/**
    * @export
    * @enum {string}
    */
export enum BotMessageResponseTypeEnum {
    SelectOne = 'select_one',
    None = 'none'
}

/**
 * 
 * @export
 * @interface BotMessageResponseDto
 */
export interface BotMessageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof BotMessageResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BotMessageResponseDto
     */
    'messageGroup': string;
    /**
     * 
     * @type {string}
     * @memberof BotMessageResponseDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BotMessageResponseDto
     */
    'type': BotMessageResponseDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BotMessageResponseDto
     */
    'responseType': BotMessageResponseDtoResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BotMessageResponseDto
     */
    'actionAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotMessageResponseDto
     */
    'actionParameters'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotMessageResponseDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof BotMessageResponseDto
     */
    'delay': number;
    /**
     * 
     * @type {string}
     * @memberof BotMessageResponseDto
     */
    'userInteractionId': string;
    /**
     * 
     * @type {Array<BotMessageResponseDto>}
     * @memberof BotMessageResponseDto
     */
    'responseOptions': Array<BotMessageResponseDto> | null;
}

/**
    * @export
    * @enum {string}
    */
export enum BotMessageResponseDtoTypeEnum {
    Message = 'message',
    Response = 'response'
}
/**
    * @export
    * @enum {string}
    */
export enum BotMessageResponseDtoResponseTypeEnum {
    SelectOne = 'select_one',
    None = 'none'
}

/**
 * 
 * @export
 * @interface BotUserInteractionMessageResponseDto
 */
export interface BotUserInteractionMessageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof BotUserInteractionMessageResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BotUserInteractionMessageResponseDto
     */
    'type': BotUserInteractionMessageResponseDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BotUserInteractionMessageResponseDto
     */
    'responseType': BotUserInteractionMessageResponseDtoResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BotUserInteractionMessageResponseDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof BotUserInteractionMessageResponseDto
     */
    'delay': number;
}

/**
    * @export
    * @enum {string}
    */
export enum BotUserInteractionMessageResponseDtoTypeEnum {
    Message = 'message',
    Response = 'response'
}
/**
    * @export
    * @enum {string}
    */
export enum BotUserInteractionMessageResponseDtoResponseTypeEnum {
    SelectOne = 'select_one',
    None = 'none'
}

/**
 * 
 * @export
 * @interface BotUserInteractionResponseDto
 */
export interface BotUserInteractionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof BotUserInteractionResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BotUserInteractionResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BotUserInteractionResponseDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof BotUserInteractionResponseDto
     */
    'answeredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotUserInteractionResponseDto
     */
    'textValueResponse'?: string;
    /**
     * 
     * @type {BotUserInteractionMessageResponseDto}
     * @memberof BotUserInteractionResponseDto
     */
    'messageResponse': BotUserInteractionMessageResponseDto;
    /**
     * 
     * @type {BotUserInteractionMessageResponseDto}
     * @memberof BotUserInteractionResponseDto
     */
    'message': BotUserInteractionMessageResponseDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CommunityMemberType {
    Member = 'member',
    HelloFamTeam = 'hello-fam-team',
    KeyOpinionLeader = 'key-opinion-leader'
}

/**
 * 
 * @export
 * @interface CreateBotMessageEntrypointDto
 */
export interface CreateBotMessageEntrypointDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBotMessageEntrypointDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBotMessageEntrypointDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBotMessageEntrypointDto
     */
    'messageGroup': string;
}
/**
 * 
 * @export
 * @interface CreateBotMessageGroupDto
 */
export interface CreateBotMessageGroupDto {
    /**
     * 
     * @type {object}
     * @memberof CreateBotMessageGroupDto
     */
    'messages': object;
    /**
     * 
     * @type {string}
     * @memberof CreateBotMessageGroupDto
     */
    'startKey': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBotMessageGroupDto
     */
    'domainKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBotMessageGroupDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateFlowChatDto
 */
export interface CreateFlowChatDto {
    /**
     * 
     * @type {FlowChatDto}
     * @memberof CreateFlowChatDto
     */
    'flow': FlowChatDto;
}
/**
 * 
 * @export
 * @interface CreateMessageDto
 */
export interface CreateMessageDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'feedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'message': string;
    /**
     * 
     * @type {AttachmentReceiveDto}
     * @memberof CreateMessageDto
     */
    'attachment'?: AttachmentReceiveDto;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface FlowChat
 */
export interface FlowChat {
    /**
     * 
     * @type {string}
     * @memberof FlowChat
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowChat
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FlowChat
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {FlowChatDto}
     * @memberof FlowChat
     */
    'flow': FlowChatDto;
    /**
     * 
     * @type {Array<Message>}
     * @memberof FlowChat
     */
    'messages': Array<Message>;
}
/**
 * 
 * @export
 * @interface FlowChatDto
 */
export interface FlowChatDto {
    /**
     * 
     * @type {string}
     * @memberof FlowChatDto
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof FlowChatDto
     */
    'messages': object;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'feedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'messageOrigin': MessageMessageOriginEnum;
    /**
     * 
     * @type {TriggerAction}
     * @memberof Message
     */
    'triggerAction': TriggerAction;
    /**
     * 
     * @type {Attachment}
     * @memberof Message
     */
    'attachment': Attachment;
    /**
     * 
     * @type {BotEntrypoint}
     * @memberof Message
     */
    'botEntrypoint': BotEntrypoint;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'flowChatId'?: string;
    /**
     * 
     * @type {FlowChat}
     * @memberof Message
     */
    'flowChat': FlowChat;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'currentFlowKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'communityPostId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageMessageOriginEnum {
    Chat = 'chat',
    ChatBot = 'chat-bot',
    ChatBotTrigger = 'chat-bot-trigger'
}

/**
 * 
 * @export
 * @interface MessageGroupItemResponseDto
 */
export interface MessageGroupItemResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MessageGroupItemResponseDto
     */
    'messageGroup': string;
    /**
     * 
     * @type {string}
     * @memberof MessageGroupItemResponseDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MessagePaginatedItemDto
 */
export interface MessagePaginatedItemDto {
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'feedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'message': string;
    /**
     * 
     * @type {AttachmentDto}
     * @memberof MessagePaginatedItemDto
     */
    'attachment'?: AttachmentDto;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'messageOrigin'?: MessagePaginatedItemDtoMessageOriginEnum;
    /**
     * 
     * @type {BotEntrypointDto}
     * @memberof MessagePaginatedItemDto
     */
    'botEntrypoint'?: BotEntrypointDto;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'flowChatId'?: string;
    /**
     * 
     * @type {FlowChatDto}
     * @memberof MessagePaginatedItemDto
     */
    'flowChat'?: FlowChatDto;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'currentFlowKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagePaginatedItemDto
     */
    'communityPostId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MessagePaginatedItemDtoMessageOriginEnum {
    Chat = 'chat',
    ChatBot = 'chat-bot',
    ChatBotTrigger = 'chat-bot-trigger'
}

/**
 * 
 * @export
 * @interface MessagePaginatedResultDto
 */
export interface MessagePaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof MessagePaginatedResultDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof MessagePaginatedResultDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof MessagePaginatedResultDto
     */
    'offset': number;
    /**
     * 
     * @type {Array<MessagePaginatedItemDto>}
     * @memberof MessagePaginatedResultDto
     */
    'results': Array<MessagePaginatedItemDto>;
}
/**
 * 
 * @export
 * @interface ParticipantDto
 */
export interface ParticipantDto {
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    'accountId': string;
    /**
     * 
     * @type {UserDto}
     * @memberof ParticipantDto
     */
    'user': UserDto;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    'lastSeenAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    'shouldReceiveNotification': boolean;
}
/**
 * 
 * @export
 * @interface ParticipantsResultDto
 */
export interface ParticipantsResultDto {
    /**
     * 
     * @type {Array<ParticipantDto>}
     * @memberof ParticipantsResultDto
     */
    'results': Array<ParticipantDto>;
}
/**
 * 
 * @export
 * @interface StartBaselineAssessmentDto
 */
export interface StartBaselineAssessmentDto {
    /**
     * 
     * @type {string}
     * @memberof StartBaselineAssessmentDto
     */
    'accountId': string;
}
/**
 * 
 * @export
 * @interface TriggerAction
 */
export interface TriggerAction {
    /**
     * 
     * @type {string}
     * @memberof TriggerAction
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TriggerAction
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TriggerAction
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof TriggerAction
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TriggerAction
     */
    'delayInMinutes': number | null;
    /**
     * 
     * @type {string}
     * @memberof TriggerAction
     */
    'topicToDispatch': string | null;
    /**
     * 
     * @type {string}
     * @memberof TriggerAction
     */
    'actionType': TriggerActionActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TriggerAction
     */
    'triggerType': TriggerActionTriggerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TriggerAction
     */
    'constraintType': TriggerActionConstraintTypeEnum;
    /**
     * 
     * @type {BotMessage}
     * @memberof TriggerAction
     */
    'botMessage': BotMessage;
}

/**
    * @export
    * @enum {string}
    */
export enum TriggerActionActionTypeEnum {
    ImmediateMessage = 'immediate_message',
    ScheduledMessage = 'scheduled_message',
    DispatchTopic = 'dispatch_topic'
}
/**
    * @export
    * @enum {string}
    */
export enum TriggerActionTriggerTypeEnum {
    MessageCreated = 'message_created',
    AccountCreated = 'account_created'
}
/**
    * @export
    * @enum {string}
    */
export enum TriggerActionConstraintTypeEnum {
    FirstMessage = 'first_message',
    NavigatorUnavailable = 'navigator_unavailable',
    NewAccount = 'new_account'
}

/**
 * 
 * @export
 * @interface UpdateBotEntrypointDto
 */
export interface UpdateBotEntrypointDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBotEntrypointDto
     */
    'interactionSession': string;
}
/**
 * 
 * @export
 * @interface UpdateParticipantDto
 */
export interface UpdateParticipantDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantDto
     */
    'lastSeenAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateParticipantDto
     */
    'shouldReceiveNotification'?: boolean;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'userType': UserDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityRole': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityBio': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityWebsiteLink': string;
    /**
     * 
     * @type {CommunityMemberType}
     * @memberof UserDto
     */
    'communityMemberType': CommunityMemberType;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDtoUserTypeEnum {
    Admin = 'admin',
    Customer = 'customer',
    Physician = 'physician',
    Coach = 'coach',
    Assistant = 'assistant'
}


/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('attachmentControllerFindOne', 'id', id)
            const localVarPath = `/v1/attachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerUploadAttachment: async (id?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerCreateMessage: async (createMessageDto: CreateMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMessageDto' is not null or undefined
            assertParamExists('messageControllerCreateMessage', 'createMessageDto', createMessageDto)
            const localVarPath = `/v1/message/test-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerCreateSystemMessage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} accountId 
         * @param {string} [after] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerFindAll: async (limit: number, offset: number, accountId: string, after?: string, before?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('messageControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('messageControllerFindAll', 'offset', offset)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('messageControllerFindAll', 'accountId', accountId)
            const localVarPath = `/v1/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = (after as any instanceof Date) ?
                    (after as any).toISOString() :
                    after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = (before as any instanceof Date) ?
                    (before as any).toISOString() :
                    before;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartBaselineAssessmentDto} startBaselineAssessmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerStartBaselineAssessment: async (startBaselineAssessmentDto: StartBaselineAssessmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startBaselineAssessmentDto' is not null or undefined
            assertParamExists('messageControllerStartBaselineAssessment', 'startBaselineAssessmentDto', startBaselineAssessmentDto)
            const localVarPath = `/v1/message/start-baseline-assessment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startBaselineAssessmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participantControllerFindAll: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('participantControllerFindAll', 'accountId', accountId)
            const localVarPath = `/v1/chat-room/{accountId}/participant`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} userId 
         * @param {UpdateParticipantDto} updateParticipantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participantControllerUpdate: async (accountId: string, userId: string, updateParticipantDto: UpdateParticipantDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('participantControllerUpdate', 'accountId', accountId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('participantControllerUpdate', 'userId', userId)
            // verify required parameter 'updateParticipantDto' is not null or undefined
            assertParamExists('participantControllerUpdate', 'updateParticipantDto', updateParticipantDto)
            const localVarPath = `/v1/chat-room/{accountId}/participant/{userId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateParticipantDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentControllerUploadAttachment(id?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentControllerUploadAttachment(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageControllerCreateMessage(createMessageDto: CreateMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageControllerCreateMessage(createMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageControllerCreateSystemMessage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageControllerCreateSystemMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} accountId 
         * @param {string} [after] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageControllerFindAll(limit: number, offset: number, accountId: string, after?: string, before?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagePaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageControllerFindAll(limit, offset, accountId, after, before, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartBaselineAssessmentDto} startBaselineAssessmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageControllerStartBaselineAssessment(startBaselineAssessmentDto: StartBaselineAssessmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageControllerStartBaselineAssessment(startBaselineAssessmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async participantControllerFindAll(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantsResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.participantControllerFindAll(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} userId 
         * @param {UpdateParticipantDto} updateParticipantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async participantControllerUpdate(accountId: string, userId: string, updateParticipantDto: UpdateParticipantDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.participantControllerUpdate(accountId, userId, updateParticipantDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerFindOne(id: string, options?: any): AxiosPromise<AttachmentDto> {
            return localVarFp.attachmentControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerUploadAttachment(id?: string, file?: any, options?: any): AxiosPromise<AttachmentDto> {
            return localVarFp.attachmentControllerUploadAttachment(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerCreateMessage(createMessageDto: CreateMessageDto, options?: any): AxiosPromise<Message> {
            return localVarFp.messageControllerCreateMessage(createMessageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerCreateSystemMessage(options?: any): AxiosPromise<Message> {
            return localVarFp.messageControllerCreateSystemMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} accountId 
         * @param {string} [after] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerFindAll(limit: number, offset: number, accountId: string, after?: string, before?: string, options?: any): AxiosPromise<MessagePaginatedResultDto> {
            return localVarFp.messageControllerFindAll(limit, offset, accountId, after, before, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartBaselineAssessmentDto} startBaselineAssessmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageControllerStartBaselineAssessment(startBaselineAssessmentDto: StartBaselineAssessmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.messageControllerStartBaselineAssessment(startBaselineAssessmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participantControllerFindAll(accountId: string, options?: any): AxiosPromise<ParticipantsResultDto> {
            return localVarFp.participantControllerFindAll(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} userId 
         * @param {UpdateParticipantDto} updateParticipantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participantControllerUpdate(accountId: string, userId: string, updateParticipantDto: UpdateParticipantDto, options?: any): AxiosPromise<ParticipantDto> {
            return localVarFp.participantControllerUpdate(accountId, userId, updateParticipantDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public attachmentControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).attachmentControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public attachmentControllerUploadAttachment(id?: string, file?: any, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).attachmentControllerUploadAttachment(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMessageDto} createMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public messageControllerCreateMessage(createMessageDto: CreateMessageDto, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).messageControllerCreateMessage(createMessageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public messageControllerCreateSystemMessage(options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).messageControllerCreateSystemMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} accountId 
     * @param {string} [after] 
     * @param {string} [before] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public messageControllerFindAll(limit: number, offset: number, accountId: string, after?: string, before?: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).messageControllerFindAll(limit, offset, accountId, after, before, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartBaselineAssessmentDto} startBaselineAssessmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public messageControllerStartBaselineAssessment(startBaselineAssessmentDto: StartBaselineAssessmentDto, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).messageControllerStartBaselineAssessment(startBaselineAssessmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public participantControllerFindAll(accountId: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).participantControllerFindAll(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {string} userId 
     * @param {UpdateParticipantDto} updateParticipantDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public participantControllerUpdate(accountId: string, userId: string, updateParticipantDto: UpdateParticipantDto, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).participantControllerUpdate(accountId, userId, updateParticipantDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatBotApi - axios parameter creator
 * @export
 */
export const ChatBotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBotMessageGroupDto} createBotMessageGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerCreate: async (createBotMessageGroupDto: CreateBotMessageGroupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBotMessageGroupDto' is not null or undefined
            assertParamExists('botMessageControllerCreate', 'createBotMessageGroupDto', createBotMessageGroupDto)
            const localVarPath = `/v1/chat-bot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBotMessageGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBotMessageEntrypointDto} createBotMessageEntrypointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerCreateBotEntrypoint: async (createBotMessageEntrypointDto: CreateBotMessageEntrypointDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBotMessageEntrypointDto' is not null or undefined
            assertParamExists('botMessageControllerCreateBotEntrypoint', 'createBotMessageEntrypointDto', createBotMessageEntrypointDto)
            const localVarPath = `/v1/chat-bot/create-entrypoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBotMessageEntrypointDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerGetAllMessageGroup: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/chat-bot/message-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} interactionSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerGetBotEntrypoint: async (interactionSession: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interactionSession' is not null or undefined
            assertParamExists('botMessageControllerGetBotEntrypoint', 'interactionSession', interactionSession)
            const localVarPath = `/v1/chat-bot/entrypoint/{interactionSession}`
                .replace(`{${"interactionSession"}}`, encodeURIComponent(String(interactionSession)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageGroup 
         * @param {string} interactionSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerGetNextQuestion: async (messageGroup: string, interactionSession: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageGroup' is not null or undefined
            assertParamExists('botMessageControllerGetNextQuestion', 'messageGroup', messageGroup)
            // verify required parameter 'interactionSession' is not null or undefined
            assertParamExists('botMessageControllerGetNextQuestion', 'interactionSession', interactionSession)
            const localVarPath = `/v1/chat-bot/next-question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (messageGroup !== undefined) {
                localVarQueryParameter['messageGroup'] = messageGroup;
            }

            if (interactionSession !== undefined) {
                localVarQueryParameter['interactionSession'] = interactionSession;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBotEntrypointDto} updateBotEntrypointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerPauseBotEntrypoint: async (updateBotEntrypointDto: UpdateBotEntrypointDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBotEntrypointDto' is not null or undefined
            assertParamExists('botMessageControllerPauseBotEntrypoint', 'updateBotEntrypointDto', updateBotEntrypointDto)
            const localVarPath = `/v1/chat-bot/pause`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBotEntrypointDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBotEntrypointDto} updateBotEntrypointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerResumeBotEntrypoint: async (updateBotEntrypointDto: UpdateBotEntrypointDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBotEntrypointDto' is not null or undefined
            assertParamExists('botMessageControllerResumeBotEntrypoint', 'updateBotEntrypointDto', updateBotEntrypointDto)
            const localVarPath = `/v1/chat-bot/resume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBotEntrypointDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnswerUserInteractionDto} answerUserInteractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botUserInteractionControllerAnswer: async (answerUserInteractionDto: AnswerUserInteractionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'answerUserInteractionDto' is not null or undefined
            assertParamExists('botUserInteractionControllerAnswer', 'answerUserInteractionDto', answerUserInteractionDto)
            const localVarPath = `/v1/user-interaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answerUserInteractionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} interactionSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botUserInteractionControllerGetUserInteractionMessages: async (interactionSession: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interactionSession' is not null or undefined
            assertParamExists('botUserInteractionControllerGetUserInteractionMessages', 'interactionSession', interactionSession)
            const localVarPath = `/v1/user-interaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (interactionSession !== undefined) {
                localVarQueryParameter['interactionSession'] = interactionSession;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatBotApi - functional programming interface
 * @export
 */
export const ChatBotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatBotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBotMessageGroupDto} createBotMessageGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async botMessageControllerCreate(createBotMessageGroupDto: CreateBotMessageGroupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.botMessageControllerCreate(createBotMessageGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBotMessageEntrypointDto} createBotMessageEntrypointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async botMessageControllerCreateBotEntrypoint(createBotMessageEntrypointDto: CreateBotMessageEntrypointDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.botMessageControllerCreateBotEntrypoint(createBotMessageEntrypointDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async botMessageControllerGetAllMessageGroup(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageGroupItemResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.botMessageControllerGetAllMessageGroup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} interactionSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async botMessageControllerGetBotEntrypoint(interactionSession: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotEntrypointDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.botMessageControllerGetBotEntrypoint(interactionSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} messageGroup 
         * @param {string} interactionSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async botMessageControllerGetNextQuestion(messageGroup: string, interactionSession: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotMessageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.botMessageControllerGetNextQuestion(messageGroup, interactionSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBotEntrypointDto} updateBotEntrypointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async botMessageControllerPauseBotEntrypoint(updateBotEntrypointDto: UpdateBotEntrypointDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.botMessageControllerPauseBotEntrypoint(updateBotEntrypointDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBotEntrypointDto} updateBotEntrypointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async botMessageControllerResumeBotEntrypoint(updateBotEntrypointDto: UpdateBotEntrypointDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.botMessageControllerResumeBotEntrypoint(updateBotEntrypointDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnswerUserInteractionDto} answerUserInteractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async botUserInteractionControllerAnswer(answerUserInteractionDto: AnswerUserInteractionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotUserInteractionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.botUserInteractionControllerAnswer(answerUserInteractionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} interactionSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async botUserInteractionControllerGetUserInteractionMessages(interactionSession: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotUserInteractionResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.botUserInteractionControllerGetUserInteractionMessages(interactionSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatBotApi - factory interface
 * @export
 */
export const ChatBotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatBotApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBotMessageGroupDto} createBotMessageGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerCreate(createBotMessageGroupDto: CreateBotMessageGroupDto, options?: any): AxiosPromise<Array<BotMessage>> {
            return localVarFp.botMessageControllerCreate(createBotMessageGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBotMessageEntrypointDto} createBotMessageEntrypointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerCreateBotEntrypoint(createBotMessageEntrypointDto: CreateBotMessageEntrypointDto, options?: any): AxiosPromise<void> {
            return localVarFp.botMessageControllerCreateBotEntrypoint(createBotMessageEntrypointDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerGetAllMessageGroup(options?: any): AxiosPromise<Array<MessageGroupItemResponseDto>> {
            return localVarFp.botMessageControllerGetAllMessageGroup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} interactionSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerGetBotEntrypoint(interactionSession: string, options?: any): AxiosPromise<BotEntrypointDto> {
            return localVarFp.botMessageControllerGetBotEntrypoint(interactionSession, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageGroup 
         * @param {string} interactionSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerGetNextQuestion(messageGroup: string, interactionSession: string, options?: any): AxiosPromise<BotMessageResponseDto> {
            return localVarFp.botMessageControllerGetNextQuestion(messageGroup, interactionSession, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBotEntrypointDto} updateBotEntrypointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerPauseBotEntrypoint(updateBotEntrypointDto: UpdateBotEntrypointDto, options?: any): AxiosPromise<void> {
            return localVarFp.botMessageControllerPauseBotEntrypoint(updateBotEntrypointDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBotEntrypointDto} updateBotEntrypointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botMessageControllerResumeBotEntrypoint(updateBotEntrypointDto: UpdateBotEntrypointDto, options?: any): AxiosPromise<void> {
            return localVarFp.botMessageControllerResumeBotEntrypoint(updateBotEntrypointDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnswerUserInteractionDto} answerUserInteractionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botUserInteractionControllerAnswer(answerUserInteractionDto: AnswerUserInteractionDto, options?: any): AxiosPromise<BotUserInteractionResponseDto> {
            return localVarFp.botUserInteractionControllerAnswer(answerUserInteractionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} interactionSession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        botUserInteractionControllerGetUserInteractionMessages(interactionSession: string, options?: any): AxiosPromise<Array<BotUserInteractionResponseDto>> {
            return localVarFp.botUserInteractionControllerGetUserInteractionMessages(interactionSession, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatBotApi - object-oriented interface
 * @export
 * @class ChatBotApi
 * @extends {BaseAPI}
 */
export class ChatBotApi extends BaseAPI {
    /**
     * 
     * @param {CreateBotMessageGroupDto} createBotMessageGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotApi
     */
    public botMessageControllerCreate(createBotMessageGroupDto: CreateBotMessageGroupDto, options?: AxiosRequestConfig) {
        return ChatBotApiFp(this.configuration).botMessageControllerCreate(createBotMessageGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBotMessageEntrypointDto} createBotMessageEntrypointDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotApi
     */
    public botMessageControllerCreateBotEntrypoint(createBotMessageEntrypointDto: CreateBotMessageEntrypointDto, options?: AxiosRequestConfig) {
        return ChatBotApiFp(this.configuration).botMessageControllerCreateBotEntrypoint(createBotMessageEntrypointDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotApi
     */
    public botMessageControllerGetAllMessageGroup(options?: AxiosRequestConfig) {
        return ChatBotApiFp(this.configuration).botMessageControllerGetAllMessageGroup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} interactionSession 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotApi
     */
    public botMessageControllerGetBotEntrypoint(interactionSession: string, options?: AxiosRequestConfig) {
        return ChatBotApiFp(this.configuration).botMessageControllerGetBotEntrypoint(interactionSession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageGroup 
     * @param {string} interactionSession 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotApi
     */
    public botMessageControllerGetNextQuestion(messageGroup: string, interactionSession: string, options?: AxiosRequestConfig) {
        return ChatBotApiFp(this.configuration).botMessageControllerGetNextQuestion(messageGroup, interactionSession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBotEntrypointDto} updateBotEntrypointDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotApi
     */
    public botMessageControllerPauseBotEntrypoint(updateBotEntrypointDto: UpdateBotEntrypointDto, options?: AxiosRequestConfig) {
        return ChatBotApiFp(this.configuration).botMessageControllerPauseBotEntrypoint(updateBotEntrypointDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBotEntrypointDto} updateBotEntrypointDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotApi
     */
    public botMessageControllerResumeBotEntrypoint(updateBotEntrypointDto: UpdateBotEntrypointDto, options?: AxiosRequestConfig) {
        return ChatBotApiFp(this.configuration).botMessageControllerResumeBotEntrypoint(updateBotEntrypointDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnswerUserInteractionDto} answerUserInteractionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotApi
     */
    public botUserInteractionControllerAnswer(answerUserInteractionDto: AnswerUserInteractionDto, options?: AxiosRequestConfig) {
        return ChatBotApiFp(this.configuration).botUserInteractionControllerAnswer(answerUserInteractionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} interactionSession 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotApi
     */
    public botUserInteractionControllerGetUserInteractionMessages(interactionSession: string, options?: AxiosRequestConfig) {
        return ChatBotApiFp(this.configuration).botUserInteractionControllerGetUserInteractionMessages(interactionSession, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheck(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlowChatApi - axios parameter creator
 * @export
 */
export const FlowChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFlowChatDto} createFlowChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flowChatControllerCreate: async (createFlowChatDto: CreateFlowChatDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFlowChatDto' is not null or undefined
            assertParamExists('flowChatControllerCreate', 'createFlowChatDto', createFlowChatDto)
            const localVarPath = `/v1/flow-chat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlowChatDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowChatApi - functional programming interface
 * @export
 */
export const FlowChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFlowChatDto} createFlowChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flowChatControllerCreate(createFlowChatDto: CreateFlowChatDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowChat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flowChatControllerCreate(createFlowChatDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlowChatApi - factory interface
 * @export
 */
export const FlowChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowChatApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFlowChatDto} createFlowChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flowChatControllerCreate(createFlowChatDto: CreateFlowChatDto, options?: any): AxiosPromise<FlowChat> {
            return localVarFp.flowChatControllerCreate(createFlowChatDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlowChatApi - object-oriented interface
 * @export
 * @class FlowChatApi
 * @extends {BaseAPI}
 */
export class FlowChatApi extends BaseAPI {
    /**
     * 
     * @param {CreateFlowChatDto} createFlowChatDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowChatApi
     */
    public flowChatControllerCreate(createFlowChatDto: CreateFlowChatDto, options?: AxiosRequestConfig) {
        return FlowChatApiFp(this.configuration).flowChatControllerCreate(createFlowChatDto, options).then((request) => request(this.axios, this.basePath));
    }
}


